<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.editar`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.contractor_brand" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Día de pago" :value="data.pay_day_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Generar factura"
                    :value="data.bill_generated ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Agrupar facturas"
                    :value="data.bill_group ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Suma D. y C. para total_amount"
                    :value="data.calculate_amount ? 'SI' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REPORTE SERVICIOS LÍNEA 12'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row
                dense
                v-for="(
                  contractor_brand_rs_type, i
                ) in data.contractor_brand_rs_types"
                :key="i"
              >
                <v-col cols="12" xs="12" md="12">
                  <ViewData
                    label="Tipo de servicio"
                    :value="contractor_brand_rs_type.rs_type.type"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <BtnCircle
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, msgConfirm, msgAlert } from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contratantes.concentradores",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: null,
      pay_days: [
        {
          id: 1,
          name: "LUNES",
        },
        {
          id: 2,
          name: "MARTES",
        },
        {
          id: 3,
          name: "MIERCOLES",
        },
        {
          id: 4,
          name: "JUEVES",
        },
        {
          id: 5,
          name: "VIERNES",
        },
        {
          id: 0,
          name: "NINGUNO",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "contractors/brands",
              this.login.token,
              this.id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.message);
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("contractors/brands", this.login.token, this.id).then((response) => {
      this.data = response;
      let pay_day = this.data.pay_day;

      this.data.pay_day_name = this.pay_days.filter(function (v) {
        return v.id === pay_day;
      });

      this.data.pay_day_name = this.data.pay_day_name[0].name;

      this.loading = false;
    });
  },
};
</script>